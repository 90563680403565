.dashBord .dropdown-menu{
  /* top: 29%;
  left: 140px; */
  background-color: #00ffee00;
}
.dashBord .dropdown-menu {
position: unset !important;
}
.dashBord .dropdown-menu a{
text-decoration: none !important;
}
.dashBord i{
color: #00FFF0;
font-size: 15px;
}

.dashBord .dropdown-item.active, .dashBord .dropdown-item:active {
  color: #c7c7c7;
  text-decoration: none;
  background-color: #007bff00;
}
.dashBord .dropdown-item:focus, .dashBord .dropdown-item:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #007bff00;
}
.dashBord .dropdown-item  {
  color: #9f9f9f;
}
.dashBord .content{
background: #0b032d
}
.dashBord .headerSpace{

  height: 70px;
}
.dashBord .child-content{
  padding-top: 20px;
/* min-height: 100vh; */
 background: #fff;
}
.dashBord .child-content form{
margin-top: 50px;
}
.dashBord .child-content .wrap{
  display: flex;
    margin: 0 53px;
    border: 1px solid #7e7e7e70;
    padding: 18px 22px;
    margin-bottom: 10px;
    border-radius: 10px;
}
.dashBord .img-box{
  position: relative;
}
.dashBord .img-box.overlay{
  height: 300px;
  overflow: overlay;
  position: relative;
}
.dashBord .child-content img{

  width: 160px;
}
.dashBord .main-content{
  min-height: 100vh;
  background: #fff;
  padding-bottom: 20px;
}
.dashBord .child-content.ClassBody{
  padding-top: 30px;

 display: grid;
 grid-template-columns: repeat(4, 1fr);
}

.dashBord .child-content .sliderClass{
  display: flex;
  margin: 0 15px;
    border: 1px solid #7e7e7e70;
    padding: 18px 22px;
    margin-bottom: 10px;
    border-radius: 10px;
}